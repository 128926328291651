.circleDivDark {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid white;
}
.circleDivLight {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #444444;
}
